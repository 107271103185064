<template>
  <div>
    <Loading :loading="loading" message="Indlæser prisrapport" />
    <v-card>
      <v-toolbar flat class="mb-2">
        <v-toolbar-title>
          Prisrapport
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <div class="d-flex align-center" style="gap: 1rem;">
          <v-checkbox
            v-model="onlyFavorites"
            label="Kun favoritter"
            hide-details
          />
          <v-menu
            v-model="dateMenu"
            transition="scale-transition"
            min-width="auto"
            offset-y
          >
            <template #activator="{ attrs, on }">
              <v-text-field
                v-model="date"
                v-bind="attrs"
                v-on="on"
                label="Dato"
                prepend-icon="mdi-calendar-outline"
                readonly
                solo
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
            />
          </v-menu>
          <v-menu
            ref="timeMenu"
            v-model="timeMenu"
            :close-on-content-click="false"
            :return-value.sync="time"
            transition="scale-transition"
            min-width="auto"
            offset-y
          >
            <template #activator="{ attrs, on }">
              <v-text-field
                v-model="time"
                v-bind="attrs"
                v-on="on"
                label="Tidspunkt"
                prepend-icon="mdi-clock-outline"
                readonly
                solo
                dense
                clearable
                hide-details
              ></v-text-field>
            </template>
            <v-time-picker
              v-model="time"
              format="24hr"
              @click:minute="$refs.timeMenu.save(time)"
            />
          </v-menu>

          <v-btn @click="fetchData">
            Genindlæs

            <v-icon right>
              mdi-reload
            </v-icon>
          </v-btn>
        </div>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        no-data-text="Ingen data"
        no-results-text="Ingen resultater"
        loading-text="Indlæser prisrapport"
        @dblclick:row="goToPart"
      >
        <template #[`item.currentCostPrice`]="{ item }">
          {{ item.currentCostPrice | formatNumberFixed2 }}
        </template>
        <template #[`item.currentPrice`]="{ item }">
          {{ item.currentPrice | formatNumberFixed2 }}
        </template>
        <template #[`item.lastCostPrice`]="{ item }">
          {{ item.lastCostPrice | formatNumberFixed2 }}
        </template>
        <template #[`item.lastPrice`]="{ item }">
          {{ item.lastPrice | formatNumberFixed2 }}
        </template>
        <template #[`item.costDiff`]="{ item }">
          {{ item.costDiff | formatNumberFixed2 }}
        </template>
        <template #[`item.listDiff`]="{ item }">
          {{ item.listDiff | formatNumberFixed2 }}
        </template>
        <template #[`item.profitDiff`]="{ item }">
          <span :class="{ 'red--text font-weight-bold': Math.sign(item.profitDiff) === -1 }">
            {{ item.profitDiff | formatNumberFixed2 }}
          </span>
        </template>
        <template #[`item.profitDiffPct`]="{ item }">
          <span :class="{ 'red--text font-weight-bold': Math.sign(item.profitDiffPct) === -1 }">
            {{ item.profitDiffPct }}
          </span>
        </template>
      </v-data-table>

      <v-card-text>
        <div>
          Procentsatser er rundet op
        </div>
        <div class="text-caption">
          Tip: Dobbeltklik på en række for at gå til redigering af en vare
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: 'PriceReport',
  components: {
    Loading
  },
  data() {
    return {
      loading: false,
      reportItems: [],
      date: this.moment().format('Y-MM-DD'),
      dateMenu: false,
      time: this.moment().format('HH:mm'),
      timeMenu: false,
      onlyFavorites: true,
      headers: [
        { text: 'Titel', value: 'title', width: '20%' },
        { text: 'EAN', value: 'ean' },
        { text: 'Varenummer', value: 'partNumber' },
        { text: 'TPA Varenummer', value: 'tpaPartNumber' },
        { text: 'Kostpris gammel', value: 'lastCostPrice', align: 'end' },
        { text: 'Kostpris aktuel', value: 'currentCostPrice', align: 'end' },
        { text: 'Kostpris ændring', value: 'costDiff', align: 'end' },
        { text: 'Kostpris ændring (%)', value: 'costDiffPct', align: 'end' },
        { text: 'Listepris gammel', value: 'lastPrice', align: 'end' },
        { text: 'Listepris aktuel', value: 'currentPrice', align: 'end' },
        { text: 'Listepris ændring', value: 'listDiff', align: 'end' },
        { text: 'Listepris ændring (%)', value: 'listDiffPct', align: 'end' },
        { text: 'Profitændring', value: 'profitDiff', align: 'end' },
        { text: 'Profitændring (%)', value: 'profitDiffPct', align: 'end' },
      ]
    }
  },
  computed: {
    items() {
      return this.reportItems.map(x => {
        return {
          ...x,
          costDiff: x.lastCostPrice ? x.currentCostPrice - x.lastCostPrice : null,
          costDiffPct: x.lastCostPrice ? (((x.currentCostPrice - x.lastCostPrice) / x.lastCostPrice) * 100).toFixed(0) : null,
          listDiff: x.lastPrice ? x.currentPrice - x.lastPrice : null,
          listDiffPct: x.lastPrice ? (((x.currentPrice - x.lastPrice) / x.lastPrice) * 100).toFixed(0) : null,
          profitDiff: x.lastPrice && x.lastCostPrice ? (x.currentPrice - x.currentCostPrice) - (x.lastPrice - x.lastCostPrice) : null,
          profitDiffPct: x.lastPrice && x.lastCostPrice
            ? (((x.currentPrice - x.currentCostPrice) - (x.lastPrice - x.lastCostPrice)) / (x.lastPrice - x.lastCostPrice) * 100).toFixed(0)
            : null
        };
      });
    },
    formattedDate() {
      return this.moment(this.date).add(this.moment.duration(this.time)).toISOString();
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;

        const response = await this.$AuthService.api.get(`customerpart/pricereport?reportDateTime=${this.formattedDate}&onlyFavorites=${this.onlyFavorites}`);

        this.reportItems = response.data?.reportItems;

      } catch (error) {
        this.$notifier.showError({});
      } finally {
        this.loading = false;
      }
    },
    goToPart(event, { item }) {
      const route = this.$router.resolve({ path: `/parts/${item.part_Id}/edit` });
      window.open(route.href, '_blank');
    },
  },
  async mounted() {
    this.fetchData();
  }
}
</script>
